import { Switch, Route } from "react-router-dom";
import "./App.css";
import { Home } from "./containers/home";

import "@fortawesome/fontawesome-free/css/all.min.css";
import { SignIn } from "./containers/signIn"
import { PrivateRoute } from "./helpers/privateRoute";

function App() {
  return (
    <Switch>
      <Route exact path='/superadmin' component={() => {
        window.location.href = 'https://admin-elevate.gpmdg.org';
        return <></>;
      }} />
      <Route exact path="/signin" component={SignIn} />
      <PrivateRoute path="/" component={Home} />
    </Switch>
  );
}

export default App;
