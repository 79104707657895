import axios from "axios";
import { config } from "../config";
import { Helpers } from "../helpers";

const API_BASE_URL = config().API_BASE_URL;

const headers = {
  "Content-Type": "application/json",
  "Accept": "application/json",
 "Access-Control-Allow-Origin": "*", }
export const ApiService = {
  signin(credentials) {
    return axios.post(`${API_BASE_URL}/evtool/api/login/`, credentials, {
      headers
    });
  },
  deleteData(body) {
    return axios.post(`${API_BASE_URL}/evtool/api/delete-data/`, body, {
      headers: {
        Authorization: `Token ${Helpers.getCookie("idToken")}`,
        ...headers
      },
    });
  },
  signOut() {
    return axios.post(
      `${API_BASE_URL}/evtool/api/logout/`,
      {},
      {
        headers: {
          Authorization: `Token ${Helpers.getCookie("idToken")}`,
          ...headers
        },
      }
    );
  },
  getProfileApi() {
    return axios.get(`${API_BASE_URL}/evtool/api/user/`, {
      headers: {
        Authorization: `Token ${Helpers.getCookie("idToken")}`,
        ...headers
      },
    });
  },
  changePasswordApi(body) {
    return axios.put(`${API_BASE_URL}/evtool/api/change-password/`, body, {
      headers: {
        Authorization: `Token ${Helpers.getCookie("idToken")}`,
        ...headers
      },
    });
  },
  evAnalysisForm(body) {
    return axios.post(`${API_BASE_URL}/evtool/api/ev-analysis/`, body, {
      timeout: 1000 * 600,
      headers: {
        Authorization: `Token ${Helpers.getCookie("idToken")}`,
        ...headers
      },
    });
  },
};
