import { Card } from "antd";

export const Dashboard = (props) => {
  return (
    <>
      <div className="pb-8 pt-5 pt-md-8 dashboard_paage">
          <Card className="dashboard_paage-bg"></Card>
      </div>
    </>
  );
};
